import React from 'react'
import styled from '@emotion/styled'
import mq from '../utils/media-queries'
import theme from '../utils/theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const FooterWrap = styled.footer`
  margin: 4rem 0 0 0;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${props => props.theme.lightGray};
  justify-self: flex-end;
  margin-top: auto;
  ${mq[2]} {
    flex-direction: row;
    justify-content: space-between;
  }
`
const Copyright = styled.small`
  order: 2;
  padding-top: 1rem;
  ${mq[2]} {
    order: 1;
  }
`
const SocialList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  & > * + * {
    padding-left: 1rem;
  }
  li {
    margin: 0;
    a {
      transition: all 0.25s;
      &:hover {
        color: ${theme.primary};
      }
    }
  }
  order: 1;
  ${mq[2]} {
    order: 2;
  }
`

const Footer = () => (
  <FooterWrap>
    <Copyright>©{new Date().getFullYear()}</Copyright>
    <SocialList>
      <li>
        <a href="https://github.com/brockduncan" title="GitHub">
          <FontAwesomeIcon icon={['fab', 'github']} />
        </a>
      </li>
      <li>
        <a href="https://twitter.com/brckdncn" title="Twitter">
          <FontAwesomeIcon icon={['fab', 'twitter']} />
        </a>
      </li>
    </SocialList>
  </FooterWrap>
)

export default Footer
