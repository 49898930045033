const theme = {
  primary: '#1DD39D',
  white: '#FAFAFA',
  black: '#141414',
  gray: '#909090',
  lightGray: '#E5E5E5',
  radius: '2px',
  shadow: '0 1px 3px 1px rgba(0,0,0,.05)',
}

export default theme

export function LightenDarkenColor(col, amt) {
  var usePound = false

  if (col[0] === '#') {
    col = col.slice(1)
    usePound = true
  }

  var num = parseInt(col, 16)

  var r = (num >> 16) + amt

  if (r > 255) r = 255
  else if (r < 0) r = 0

  var b = ((num >> 8) & 0x00ff) + amt

  if (b > 255) b = 255
  else if (b < 0) b = 0

  var g = (num & 0x0000ff) + amt

  if (g > 255) g = 255
  else if (g < 0) g = 0

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
}
