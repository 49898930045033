import React from 'react'
import { css } from '@emotion/core'

const Container = props => (
  <div
    css={css`
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 1.5rem;
    `}
  >
    {props.children}
  </div>
)

export default Container
