import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styled from '@emotion/styled'
import logoLight from '../images/logo-light.svg'
import logo from '../images/logo.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { css } from '@emotion/core'
import { Link } from 'gatsby'
import mq from '../utils/media-queries'

import Nav from '../components/nav'

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: auto;
  max-width: 1200px;
  color: ${props => (props.light ? props.theme.white : props.theme.black)};
`

export default class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      opened: false,
    }
  }
  componentDidUpdate() {
    this.state.opened
      ? document.querySelector('html').setAttribute('style', 'overflow:hidden;')
      : document.querySelector('html').removeAttribute('style')
    console.log(this.state.opened)
  }
  onNavClose = () => {
    this.setState({
      opened: !this.state.opened,
    })
  }

  onNavOpen = () => {
    this.setState({
      opened: !this.state.opened,
    })
  }
  render() {
    return (
      <>
        <HeaderWrap
          light={this.props.light}
          css={{
            zIndex: this.state.opened ? '10' : '1',
            overflow: this.state.opened ? 'scroll-y' : 'hidden',
          }}
        >
          <Link
            to="/"
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <img
              src={this.props.light ? logoLight : logo}
              css={css`
                margin-bottom: 0;
              `}
              alt={'logo'}
            />
          </Link>
          <FontAwesomeIcon
            icon={'bars'}
            css={css`
              font-size: 32px;
              cursor: pointer;
              ${mq[2]} {
                display: none;
              }
            `}
            onClick={this.onNavOpen}
            width="32"
          />
          <Nav
            opened={this.state.opened}
            onNavClose={this.onNavClose}
            light={this.props.light}
          />
        </HeaderWrap>
      </>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}
