/** @jsx jsx */
import { Component } from 'react'
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'gatsby'
import mq from '../utils/media-queries'

const NavWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  background: ${props => props.theme.black};
  color: ${props => props.theme.white};
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  transition: opacity 0.15s;
  opacity: 0;
  ${mq[2]} {
    z-index: 1;
    opacity: 1;
    background: none;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: flex-start;
    height: auto;
    width: auto;
    color: ${props => (props.light ? props.theme.white : props.theme.black)};
    position: relative;
  }
`
const NavList = styled.ul`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  ${mq[2]} {
    flex-flow: row nowrap;
  }
`
const NavListItem = styled.li`
  margin: 1.5rem 2rem;
  font: bold 20px 'Open Sans';
  text-transform: uppercase;
  ${mq[2]} {
    &:first-of-type {
      display: none;
    }
    &:hover {
      color: ${props =>
        props.light
          ? props => props.theme.white
          : props => props.theme.primary};
    }
    transition: all ease-in-out 100ms;
  }
  & .active {
    color: ${props => props.theme.primary};
  }
`
export default class Nav extends Component {
  componentDidUpdate() {
    const nav = document.getElementById('nav-wrap')
    if (this.props.opened) {
      nav.classList.add('nav-open')
    } else {
      nav.classList.remove('nav-open')
    }
  }
  render() {
    return (
      <NavWrap id={'nav-wrap'} light={this.props.light}>
        <style>{`
          .nav-open {
            z-index: 9999;
            opacity: 1;
          }
        `}</style>
        <FontAwesomeIcon
          icon={'times'}
          css={css`
            position: absolute;
            top: 1.5rem;
            right: 1.5rem;
            font-size: 1.5rem;
            cursor: pointer;
            ${mq[2]} {
              display: none;
            }
          `}
          onClick={this.props.opened ? this.props.onNavClose : null}
        />
        <NavList>
          <NavListItem>
            <Link
              to="/"
              activeClassName="active"
              onClick={this.props.opened ? this.props.onNavClose : null}
            >
              Home
            </Link>
          </NavListItem>
          <NavListItem>
            <Link
              to="/blog"
              activeClassName="active"
              onClick={this.props.opened ? this.props.onNavClose : null}
            >
              Blog
            </Link>
          </NavListItem>
          <NavListItem>
            <Link
              to="/contact"
              activeClassName="active"
              onClick={this.props.opened ? this.props.onNavClose : null}
            >
              Contact
            </Link>
          </NavListItem>
        </NavList>
      </NavWrap>
    )
  }
}
